import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'

export default function Pricing(props) {
	return <>
		<Helmet>
			<title>Pricing | Kelgrand</title>
		</Helmet>

		<Header path={props.path} />

		<main className='container'>
			<div className='row'>
				<div className='col-sm-6'>
					<h1>
						Pricing
					</h1>
					<p>
						Pricing depends on number of users, selected application, features, additional services … We offer annual and monthly billing. Applications are usually in range 50 - 250 EUR + VAT per month + initial deployment &amp; training.
					</p>
					<p>
						We offer free demo so that you can try before committing. Contact us for a free demo.
					</p>
				</div>
			</div>
		</main>

		<Footer />
	</>
}
